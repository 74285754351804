import React from "react";
import AllergensTable from "./AllergensTable";

const AllergensPage = () => {
  return (
    <div>
      <AllergensTable />
    </div>
  );
};

export default AllergensPage;
